import * as React from "react";
import Navbar from "../components/navbar";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import GoBackButton from "../components/goBackButton";

const Product = () => {
  const [mobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    const { innerWidth: width } = window;
    width > 750 ? setMobile(false) : setMobile(true);
  }, []);

  const elementRef = React.useRef();
  const [elHeight, setElHeight] = React.useState(315);
  React.useEffect(() => {
    setElHeight(elementRef.current?.clientHeight);
  }, [elementRef]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Merth.dev CMS</title>
      </Helmet>
      <Navbar />
      <main className=" bg-primary">
        <h1 className="text-center">Content Management System</h1>
        <div className="row justify-content-center">
          <div className="row py-4 h-100 ">
            <div
              className={`col-md-6 text-center  h-100 align-self-center ${
                mobile ? "g-0 pb-1" : "px-4"
              }`}
            >
              <iframe
                width="100%"
                height={`${!mobile ? elHeight + "px" : ""}`}
                src="https://www.youtube.com/embed/USaCft7hAfk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div ref={elementRef} className="col-md-6 bg-paragraph">
              <p
                className={` ${mobile ? "p-2 fs-6" : "p-4 justify-text fs-5"}`}
              >
                This application is designed to streamline the creation and
                modification of offers and final contracts, primarily serving
                sellers. It accommodates businesses with multiple branches, each
                having its manager and sellers. A supervisor role ensures
                comprehensive oversight, while strict access control prevents
                sellers from viewing each other's offers and contracts. Branch
                managers have access limited to their specific branch,
                simplifying their management tasks. This system optimizes
                operations and privacy for businesses with diverse branch
                structures.
                <br />
                <i>
                  <small>
                    Used technologies : ReactJs, NodeJS and MongoDB/mongoose
                  </small>
                </i>
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${mobile ? "" : "px-3"}`}>
          <StaticImage
            src={`./../images/cms.jpg`}
            alt="service"
            placeholder="blurred"
          />
        </div>
        <GoBackButton />
      </main>
    </>
  );
};

export default Product;
