import { Link } from "gatsby";
import * as React from "react";

const GoBackButton = () => {
  return (
    <Link to="/">
      <button className="btn btn-sm btn-secondary floating-button">
        <i className="bi bi-house text-bold"></i>Home
      </button>
    </Link>
  );
};

export default GoBackButton;
